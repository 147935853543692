<template>
	<form class="form-horizontal" @submit.prevent="save">
		<div class="animated fadeIn">
			<b-card>
				<div class="display-table full-width mb-2">
					<strong class="card-title">{{$route.meta.label}}</strong>
				</div>
        <b-row class="mb-5">
          <b-col>
            <div class="image-input">
							<b-img :src="getUrlOfFile(models.image)" class="mb-2" style="max-height: 160px; border: none"/>
						</div>
          </b-col>
        </b-row>
				<b-row>
					<b-col sm="6" class="mb-2">
            <div class="mb-4">
              <h6>Title</h6>
              <span>{{models.title}}</span>
            </div>
            <div class="mb-4">
              <h6>Slug</h6>
              <span>{{models.slug || '-'}}</span>
            </div>
            <div class="mb-4">
              <h6>Description</h6>
              <div v-html="models.description"></div>
            </div>
					</b-col>
					<b-col sm="6" class="mb-2">
						
            <div class="mb-4">
              <h6>Meta Keyword</h6>
              <span>{{models.meta.keyword}}</span>
            </div>
            <div class="mb-4">
              <h6>Meta Description</h6>
              <span>{{models.meta.description}}</span>
            </div>
						<div class="mb-4">
              <h6>Status</h6>
              <span :class="'badge badge-' + getClassStatus(models.status)" class="text-capitalize">
                {{models.status}}
              </span>
            </div>
					</b-col>
				</b-row>
				<template #footer>
					<b-button type="button" variant="light" block class="btn-min-width rounded float-left" @click="$router.push({name: config.uri})">
						Cancel
					</b-button>
				</template>
			</b-card>
		</div>
	</form>
</template>
<script>
	import api from '@/utils/api.js'
  import { getClassStatus } from '@/utils/helpers';
	import {
		getUrlOfFile
	} from '@/utils/helpers.js';

	export default {
		name: 'DetailPage',
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
				},
				models: {
					title: '',
					description: '',
					image: '',
					meta: {
						description: '',
						keyword: ''
					},
					status: ''
				},
			}
		},
		created() {
			let _ = this
			_.getById();
		},
		methods: {
			getById() {
				const _ = this;
				if (_.$route.params.id) {
					_.$axios.get(`${api.page}/${_.$route.params.id}`)
						.then(res => {
							const { data } = res.data;
							_.models.title = data.title;
							_.models.description = data.description;
							_.models.image = data.image;
							_.models.meta.description = data.meta.description;
							_.models.meta.keyword = data.meta.keyword;
							_.models.status = data.status;
						}).catch(() => _.$router.push({
							name: 'page404'
						}));
				}
			},
      getClassStatus(status) {
				return getClassStatus(status);
			},
			getUrlOfFile(file) {
				return getUrlOfFile(file, true);
			},
		},
	}
</script>